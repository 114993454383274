import React from 'react'
import Fade from 'react-reveal/Fade';

const About = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }

    return (
        <div>   
            <div className="regular_text">
                <Fade bottom delay={0} duration={300}>
                    <div>I design and write code.</div>
                </Fade>
                <Fade bottom delay={100} duration={300}>
                    <div>I like working on product strategy</div>
                </Fade>
                <Fade bottom delay={200} duration={300}>
                    <div>and shipping beautiful solutions.</div>
                </Fade>
                <Fade bottom delay={300} duration={300}>
                    <div>You can learn more about me <a
                        href="/about"
                        onMouseOver={props.mouseHover}
                        onMouseLeave={props.mouseLeave}
                        className="inline_link">here</a>.
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default About
