import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer/Footer'
import MyResume from './JustinStalsResume.pdf'
import { pdfjs, Document, Page } from 'react-pdf';
import './Resume.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = {            
        }
    }
    downloadResume = () => {
		fetch('https://firebasestorage.googleapis.com/v0/b/justinstalscom.appspot.com/o/Justin%20Stals%20Resume.pdf')
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'JustinStalsResume.pdf';
					a.click();
				});
		});
	}

    
    render() {
        let phoneClasses = "phone"
        if (this.state.phoneNum) {
            phoneClasses = "phone visible"
        }

        return (
            <div>
                <div id="nav_bg"></div>
                <div className="resume">
                    <Document
                        className="document"
                        file={MyResume}
                    >
                        <Page className="page" pageNumber={1} height={500} />
                    </Document>
                    <button onClick={this.downloadResume} className="download-btn">
                        <div className="text">
                            Download
                        </div>
                        <div className="icon">
                            <svg width="14px" height="17px" viewBox="0 0 14 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g id="Group-15" transform="translate(1.000000, 1.000000)" fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#FFFFFF" strokeWidth={2}>
                                    <path d="M5.8858869,0 L5.8858869,14.2840765" id="Path-261" />
                                    <polyline id="Path-262" strokeLinejoin="round" points="0 8.36153931 5.8858869 14.2474262 11.7717738 8.36153931" />
                                </g>
                            </svg>
                        </div>
                    </button>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Resume
