import React, { Component } from 'react';
// import Typist from 'react-typist';
// import TypistLoop from 'react-typist-loop';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer/Footer';
import './About.css';
import { NavLink } from 'react-router-dom';

class About extends Component {
    render() {
        return (
            <div className="about">
                <div id="nav_bg"></div>
                    <section className="component-wrap" id="section_1">
                        <div className="width-constraint">                     
                            <p>
                                <Fade bottom>
                                    I'm a designer and software engineer from Sydney, Australia.
                                </Fade>
                            </p>
                            <p>
                                <Fade bottom>
                                    I have B.Com and B.Sci degrees from Sydney University.
                                </Fade>
                            </p>
                            <p>
                                <Fade bottom>
                                    <div>
                                        Over the last few years, I've worked with the teams at <a href="https://www.stake.com.au" rel="noopener noreferrer" onMouseOver={this.props.mouseHover} onMouseLeave={this.props.mouseLeave}>Stake</a> and <a href="https://www.airtree.vc" rel="noopener noreferrer" onMouseOver={this.props.mouseHover} onMouseLeave={this.props.mouseLeave}>AirTree Ventures</a>.
                                    </div>
                                </Fade>
                            </p>
                            <p>
                                <Fade bottom>
                                    <div>
                                        Now I do design and front-end for <a
                                        href="https://paytron.com.au"
                                        rel="noopener noreferrer"
                                        onMouseOver={this.props.mouseHover}
                                        onMouseLeave={this.props.mouseLeave}>Paytron</a>. You can check out some highlights of my older work <NavLink
                                        to="/projects"
                                        onMouseOver={this.props.mouseHover}
                                        onMouseLeave={this.props.mouseLeave}>here</NavLink>.
                                    </div>
                                </Fade>
                            </p>
                            <p>
                                <Fade bottom>
                                    <div>
                                        If you'd like to discuss working together, <a href="/contact" onMouseOver={this.props.mouseHover} onMouseLeave={this.props.mouseLeave}>let me know</a>.
                                    </div>
                                </Fade>
                            </p>
                            <p>
                                <Fade bottom>
                                    <div>
                                        If you want to check out my resume, head <NavLink to="/resume" onMouseOver={this.props.mouseHover} onMouseLeave={this.props.mouseLeave}>here</NavLink>.
                                    </div>
                                </Fade>
                            </p>
                        </div>
                    </section>
                    <Footer />
            </div>
        )
    }
}

export default About
