import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Projects from './components/Projects/Projects';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Resume from './components/Resume/Resume';
import Header from './components/Header/Header';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import disableScroll from 'disable-scroll';
import './index.css';



class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            menuOpen: false,
            mouseHover: false,
            scrollPercentage: 0,
            scrollPixels: 0,
            pageHeight: 0,
            scrollHeight: 0,
            contentHeight: 0,
            pathIndex: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    listenToScroll = () => {
        const pageHeight = window.innerHeight
        const scrollPixels = window.scrollY
        const scrollPercentage = scrollPixels / pageHeight
        const contentHeight = pageHeight + scrollPixels
        const scrollHeight = scrollPixels - this.state.scrollPixels

        this.setState({
            pageHeight: pageHeight,
            scrollPixels: scrollPixels,
            contentHeight: contentHeight,
            scrollHeight: scrollHeight
        })
    }

    _onMouseMove(e) {
        if (this.state.scrollPixels > 0) {
            this.setState({ x: Math.min(e.pageX, this.state.width - 30), y: Math.min(e.pageY, (this.state.contentHeight-15)) });
        }
        else {
            this.setState({ x: Math.min(e.pageX, this.state.width - 30), y: Math.min(e.pageY, (this.state.pageHeight-15)) });
        }
    }

    menuToggle = () => {
        
        if (this.state.menuOpen) {
            this.setState({
                menuOpen: false,
            });
            disableScroll.off();
        } else {
            this.setState({
                menuOpen: true,
            });
            disableScroll.on();
        }
      };

    _mouseHover() {
        this.setState({
            mouseHover: true,
        });
    };

    _mouseLeave() {
        this.setState({
            mouseHover: false,
        });
    };

    componentDidMount() {

        const pageHeight = document.documentElement.clientHeight
        
        this.setState({
            pageHeight: pageHeight
        })

        this._onMouseMove.bind(this);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.listenToScroll)
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.listenToScroll)
      }
      
      updateWindowDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            pageHeight: window.innerHeight
        });
      }

    render() {
        const { x, y, width, height, menuOpen, mouseHover } = this.state;

        let cursorColor = "#333";
        if (menuOpen) {
            cursorColor = "#F4F4F4";
        }
        let overflow = "visible";
        
        let mouseTrail = "block";
        if (width < 900) {
            mouseTrail = "none"
        }
        
        
        return (
            <Router>
                <div className="cursor_wrap" onMouseMove={this._onMouseMove.bind(this)}>
                { mouseHover
                    ? 
                    <div style={{display: mouseTrail}}>
                        <div className="cursor active" style={{ top: (y - 29) + "px", left: (x - 27) + "px", backgroundColor: "#F4F4F4"}}></div>
                        <div className="cursor_trail active" style={{ top: (y - 32) + "px", left: (x - 30) + "px"}}></div>
                    </div>
                    : 
                    <div style={{display: mouseTrail}}>
                        <div className="cursor" style={{ top: (y - 5) + "px", left: (x - 5) + "px", backgroundColor: cursorColor}}></div>
                        <div className="cursor_trail" style={{ top: (y - 11) + "px", left: (x - 11) + "px"}}></div>
                    </div>
                }
                <Header
                    menuOpen={this.state.menuOpen}
                    menuToggle={this.menuToggle}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                />
                <ScrollToTop>
                <Route
                    exact path='/'
                    render={(props) => <Home {...props}
                    menuOpen={menuOpen}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                    />}
                />
                <Route
                    path='/projects'
                    render={(props) => <Projects {...props}
                    menuOpen={menuOpen}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                    />}
                />
                <Route
                    path='/about'
                    render={(props) => <About {...props}
                    menuOpen={menuOpen}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                    />}
                />
                <Route
                    path='/contact'
                    render={(props) => <Contact {...props}
                    menuOpen={menuOpen}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                    />}
                />
                <Route
                    path='/resume'
                    render={(props) => <Resume {...props}
                    menuOpen={menuOpen}
                    mouseHover={this._mouseHover.bind(this)}
                    mouseLeave={this._mouseLeave.bind(this)}
                    />}
                />
                </ScrollToTop>
                </div>
            </Router>
        )
    }
}

export default Routes;
