import React, { Component } from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';
// import { render } from 'node-sass';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameActive: false,
            emailActive: false,
            messageActive: false,
        }
    }
    setActive = field => {
        const fieldVar = field + "Active"
        this.setState({ [fieldVar]: true})
    }
    setInactive = field => {
        const fieldVar = field + "Active"
        if (this.props[field] === "") this.setState({ [fieldVar]: false})
    }
    render() {
        const {
            nameActive,
            emailActive,
            messageActive
        } = this.state;
        const {
            menuOpen,
            topicToggle,
            emailInvalid,
            name,
            email,
            message
        } = this.props;

        let headlineClasses = "headline"
        if (menuOpen) {
            headlineClasses = "headline hidden"
        }
        let dropdownClasses = "topic_dropdown hidden"
        if (topicToggle) {
            dropdownClasses = "topic_dropdown"
        }

        let aboutText = "I'd like to get in touch about "
        let aboutText2 = "Maybe we can chat about it over a coffee."
        let emailColour = "#FF5E0D";
        if (emailInvalid) {
            emailColour = "#FF1D0D";
        }
        return (
            <div className={headlineClasses}>   
                <div className="regular_text form">
                    <div className="title">
                        <Fade bottom delay={0} duration={300}>
                            <div>
                                <h1>Get in touch</h1>
                                <p>Fill out the form and I'll get back to you ASAP.</p>
                            </div>
                        </Fade>
                    </div>
                    <div className="form-bg">
                        <div className="inputs">
                            <Fade bottom delay={100} duration={300}>
                                <div>
                                    <div className="input-group small">
                                        <label
                                            className={nameActive ? "selected" : "" }
                                            htmlFor="name"
                                        >Full name</label>
                                        <input
                                            onFocus={() => this.setActive("name")}
                                            onBlur={() => this.setInactive("name")}
                                            onChange={this.props.updateName}
                                            name="name"
                                            type="text"
                                            placeholder=""
                                            onMouseOver={this.props.mouseHover}
                                            onMouseLeave={this.props.mouseLeave}>
                                        </input>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200} duration={300}>
                                <div>
                                    <div className="input-group small">
                                        <label
                                            className={emailActive ? "selected" : "" }
                                            htmlFor="name"
                                        >Email address</label>
                                        <input
                                            onFocus={() => this.setActive("email")}
                                            onBlur={() => this.setInactive("email")}
                                            onChange={this.props.updateEmail}
                                            name="email"
                                            type="text"
                                            placeholder=""
                                            onMouseOver={this.props.mouseHover}
                                            onMouseLeave={this.props.mouseLeave}>
                                        </input>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300} duration={300}>
                                <div>
                                    <div className="input-group large">
                                        <label
                                            className={messageActive ? "selected" : "" }
                                            htmlFor="name"
                                        >Message</label>
                                        <textarea
                                            onFocus={() => this.setActive("message")}
                                            onBlur={() => this.setInactive("message")}
                                            onChange={this.props.updateMessage}
                                            name="message"
                                            rows={3}
                                            placeholder=""
                                            onMouseOver={this.props.mouseHover}
                                            onMouseLeave={this.props.mouseLeave}>
                                        </textarea>
                                    </div>
                                </div>
                            </Fade>
                            {/* <ul className={dropdownClasses}>
                                { props.messageTopics && props.messageTopics.map((topic, index) => {
                                    return (
                                        <li key={index} onClick={() => props.setActiveTopic(index)}>
                                            {topic}
                                        </li>
                                    )
                                })}
                            </ul>
                            <Fade bottom delay={200} duration={300}>
                            </Fade>
                            <Fade bottom delay={300} duration={300}>

                            </Fade>
                            <Fade bottom delay={400} duration={300}>
                                <div>Thanks,</div>
                            </Fade>
                            <Fade bottom delay={500} duration={300}>
                                <button
                                    className="topic_toggle"
                                    onMouseOver={props.mouseHover}
                                    onMouseLeave={props.mouseLeave}
                                    onClick={props.toggleTopicMenu}>{props.activeTopic} <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                        <polyline strokeWidth={3} points="11.0439692 1.64873623 11.0439692 18.0718514 2 9.0278822 11.0439692 18.0718514 20.0879385 9.0278822" />
                                    </g>
                                </svg>
                                </button>
                            </Fade>
                            */}
                        </div>
                        <div className="submit">
                            <Fade bottom delay={400} duration={300}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <button
                                        className={"contact_form_btn"}
                                        disabled={(name === "" && email === "" && message === "")}
                                        onClick={this.props.submitForm}
                                        onMouseOver={this.props.mouseHover}
                                        onMouseLeave={this.props.mouseLeave}>
                                        <div className="text">
                                            Submit
                                        </div>
                                        <div className="icon">
                                            <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <g stroke="none" strokeWidth={1} fillRule="evenodd">
                                                    <path id="Mail" fillRule="nonzero" d="M17.9083552,0.0681402012 C17.8282301,-0.00357541714 17.714204,-0.0202138799 17.6175589,0.0255281646 L0.155793021,8.3047833 C0.0617846854,8.34937218 0.00124697627,8.44508453 0,8.55084584 C-0.0010130982,8.65660716 0.057533593,8.75363741 0.150627137,8.80025805 L5.09227998,11.2750509 C5.1828982,11.3204635 5.29095129,11.3101949 5.37188348,11.2484733 L13.0421458,4.91949157 L6.4046837,11.5431333 C6.3508724,11.5996382 6.32342864,11.6770098 6.32934789,11.7555346 L6.70489693,16.7464694 C6.71323768,16.857063 6.78588293,16.9516222 6.88914681,16.9862719 C6.916752,16.9954972 6.94511056,17 6.97314624,17 C7.05004259,17 7.12484029,16.9663387 7.17687581,16.9048368 L9.79974605,13.8032736 L13.0421458,15.3842021 C13.1125309,15.4185224 13.1940012,15.4199501 13.2655165,15.3882107 C13.3370317,15.3564164 13.3914887,15.294585 13.4148428,15.2186961 L17.9876192,0.356814784 C18.0195831,0.253030314 17.9884264,0.139855819 17.9083552,0.0681402012 Z" />
                                                </g>
                                            </svg>
                                        </div>                         
                                    </button>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form
