
export const contact = (newContact) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('messages').add({ 
            email: newContact.email,
            name: newContact.name,
            message: newContact.message,
            sentAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_CONTACT', newContact });
        }).catch((err) => {
            dispatch({ type: 'ADD_CONTACT_ERROR', err})
            console.log(err);
        })
    }
}