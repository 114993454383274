import React, { Component } from 'react';
import Footer from '../Footer/Footer';
// import { CSSGrid, measureItems, makeResponsive, layout } from 'react-stonecutter';
import Zoom from 'react-reveal/Zoom';
import moment from 'moment'
import './Projects.css';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import ProjectImage from './ProjectImage'
import loader from '../Home/Loader.gif'

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeIndex: null,
            focusedProject: null,
            scrollTop: 0
        }
        this.focusProject = this.focusProject.bind(this);
        this.nextProject = this.nextProject.bind(this);
        this.removeFocusedProject = this.removeFocusedProject.bind(this);
        this.myRef = React.createRef()
    }

    focusProject(index, projectID) {
        let project = this.props.projects.filter(project => project.id === projectID)[0];
        this.setState({
            activeIndex: index,
            focusedProject: project
        });
    }
    removeFocusedProject() {
        this.setState({
            activeIndex: null,
            focusedProject: null
        });
    }
    nextProject(index) {
        this.focusProject(index+1, this.props.projects[index+1].id)
    }
    prevProject(index) {
        this.focusProject(index-1, this.props.projects[index-1].id)
    }

    
    componentDidMount() {
        this.setState({ loading: false })
    }

    render() {
        let projects = this.props.projects;
        let focusedProject = this.state.focusedProject;
        return (
            this.state.loading ? <div className="loading projects"><img src={loader} /></div> :
            <div className="projects">
                <div id="nav_bg"></div>
                    <section className="component-wrap">
                        <div className="width-constraint">
                            { focusedProject ?
                                <div className="project_focused_wrap"> 
                                    <div className="project_focused_bg" onClick={this.removeFocusedProject} />
                                    <div className="project_focused">
                                        <div className="top_info">
                                            <div className="info">
                                                <h3>{focusedProject.name}</h3>
                                                <p>{moment(focusedProject.created.toDate()).format('LL')}</p>
                                            </div>
                                            <div className="close_btn">
                                                <button onClick={this.removeFocusedProject}>
                                                    <div />
                                                    <div />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="image_wrap">
                                            { this.state.activeIndex && this.state.activeIndex > 0 ?
                                            <button
                                                onClick={() => this.prevProject(this.state.activeIndex)}
                                                className="project_btn previous">
                                                    <svg width="40px" height="59px" viewBox="0 0 40 59" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                        <defs>
                                                        <polygon id="path-1" points="10.8284271 8 32.0416306 29.2132034 32.034 29.221 32.0416306 29.2284271 10.8284271 50.4416306 8 47.6132034 26.393 29.221 8 10.8284271" />
                                                        <filter x="-53.4%" y="-30.2%" width="206.7%" height="160.5%" filterUnits="objectBoundingBox" id="filter-2">
                                                            <feMorphology radius={1} operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                                                            <feOffset dx={0} dy={0} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                                                            <feGaussianBlur stdDeviation={3} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                                            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" />
                                                        </filter>
                                                        </defs>
                                                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g id="Path">
                                                            <use fill="black" fillOpacity={1} filter="url(#filter-2)" xlinkHref="#path-1" />
                                                            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
                                                        </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                                : null
                                            }
                                            { projects && this.state.activeIndex < projects.length-1 ?
                                            <button
                                                onClick={() => this.nextProject(this.state.activeIndex)}
                                                className="project_btn next">
                                                    <svg width="40px" height="59px" viewBox="0 0 40 59" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                        <defs>
                                                        <polygon id="path-1" points="10.8284271 8 32.0416306 29.2132034 32.034 29.221 32.0416306 29.2284271 10.8284271 50.4416306 8 47.6132034 26.393 29.221 8 10.8284271" />
                                                        <filter x="-53.4%" y="-30.2%" width="206.7%" height="160.5%" filterUnits="objectBoundingBox" id="filter-2">
                                                            <feMorphology radius={1} operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                                                            <feOffset dx={0} dy={0} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                                                            <feGaussianBlur stdDeviation={3} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                                            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" />
                                                        </filter>
                                                        </defs>
                                                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <g id="Path">
                                                            <use fill="black" fillOpacity={1} filter="url(#filter-2)" xlinkHref="#path-1" />
                                                            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
                                                        </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                                : null
                                            }
                                            {/* <img src={focusedProject.imageLarge}/> */}
                                            <ProjectImage
                                                class="project_image_large"
                                                src={focusedProject.imageLarge}
                                                // unloadedSrc={project.imageSmall}
                                            />
                                        </div>
                                        <div className="share">
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" className="pinterest">
                                                    <title id="gg7tr0tzs3d2ztrl41yrptn0lw3gx21">Pinterest icon</title>
                                                    <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"></path>
                                                </svg>
                                                Pin
                                            </a>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" className="twitter">
                                                    <title id="dziuhzt2ibfsq0smu47iolhaxglyguk">Twitter icon</title>
                                                    <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"></path>
                                                </svg>
                                                Tweet
                                            </a>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" role="img" className="copy">
                                                    <path d="m7.438 16.562c.293.293.677.44 1.061.44s.768-.147 1.061-.44l7.002-7.002c.586-.586.586-1.536 0-2.122s-1.536-.586-2.122 0l-7.002 7.002c-.586.586-.586 1.536 0 2.122zm3.501 3.078c-1.813 1.814-4.765 1.814-6.58 0-1.814-1.814-1.814-4.765 0-6.579l3.29-3.29-2.121-2.121-3.29 3.29c-2.984 2.984-2.984 7.839 0 10.823 1.492 1.491 3.452 2.237 5.412 2.237s3.92-.746 5.411-2.238l3.29-3.29-2.122-2.122zm10.823-17.402c-2.983-2.984-7.839-2.984-10.823 0l-3.29 3.29 2.122 2.122 3.29-3.29c.907-.907 2.098-1.36 3.289-1.36s2.383.454 3.29 1.361c1.814 1.814 1.814 4.765 0 6.579l-3.29 3.29 2.122 2.122 3.29-3.29c2.984-2.985 2.984-7.84 0-10.824z"></path>
                                                </svg>
                                                Copy
                                            </a>
                                        </div>
                                        <div className="tags_colours">
                                            <div className="tags">
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" role="img" className="icon "><path d="m21.818 0h-7.644c-.574 0-1.125.226-1.534.629l-12.001 11.823c-.852.853-.852 2.234 0 3.087l7.823 7.823c.852.852 2.234.852 3.087 0l11.822-12.002c.403-.409.629-.96.629-1.534v-7.644c0-1.205-.977-2.182-2.182-2.182zm-3.818 8c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.895 2-2 2z"></path></svg>
                                                { focusedProject.tags && focusedProject.tags.map((tag, index) => {
                                                    return (
                                                        <p key={index}>{tag}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className="date">
                                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" role="img" className="icon "><path d="m0 22c0 1.105.896 2 2 2h20c1.105 0 2-.896 2-2v-11h-24zm22-20h-3v-1c0-.552-.448-1-1-1s-1 .448-1 1v1h-10v-1c0-.552-.448-1-1-1s-1 .448-1 1v1h-3c-1.104 0-2 .896-2 2v5h24v-5c0-1.104-.896-2-2-2zm-16 5c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm12 0c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"></path></svg>
                                                <p>{moment(focusedProject.created.toDate()).format('L')}</p>
                                            </div>
                                            <div className="colours">
                                                { focusedProject.colours && focusedProject.colours.map((colour, index) => {
                                                    return (
                                                        <div key={index} title={colour} style={{backgroundColor: colour}}></div>
                                                    )
                                                })}
                                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" role="img" className="icon "><path d="m23.968 11.113c-.413-5.752-5.032-10.5-10.772-11.055-7.152-.692-13.174 4.9-13.196 11.904-.007 2.222 1.778 4.038 4 4.038h2.297c1.364 0 2.423 1.343 1.949 2.622-.236.638-.313 1.352-.183 2.092.341 1.939 2.135 3.311 4.104 3.285 6.843-.093 12.302-5.914 11.801-12.886zm-16.968-3.113c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm7-1c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm4 12c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm1-7c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path></svg>
                                            </div>
                                        </div>
                                        <div className="project_description">
                                            <p>
                                                {focusedProject.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            : null}
                            {/* <h1>projects.</h1> */}
                            <Zoom>
                                <ul className="projects_grid">
                                    { projects && projects.map((project, index) => {
                                        if (project.hidden) return
                                        return (
                                            <li key={project.id} className={index === this.state.activeIndex ? "active" : ""}  onMouseOver={this.props.mouseHover} onMouseLeave={this.props.mouseLeave} onClick={() => this.focusProject(index, project.id)}>
                                                <div>
                                                    <ProjectImage
                                                        class="project_image_small"
                                                        src={project.imageSmall}
                                                        // unloadedSrc={project.imageSmall}
                                                    />
                                                    <div className="project_tags">
                                                        { project.tags && project.tags.map((tag, index) => {
                                                            return (
                                                                <p key={index}>{tag}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="project_info">
                                                        <h3>{project.name}</h3>
                                                        <p>{moment(project.created.toDate()).format('LL')}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Zoom>
                        </div>
                    </section>
                    <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        projects: state.firestore.ordered.projects
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'projects', orderBy: ['created', 'desc'] }
    ])
)(Projects)
