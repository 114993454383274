import React from 'react'
import Fade from 'react-reveal/Fade';
import loader from '../Loader.gif';

const Intro = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }
    // spy={ props.imgLoadComplete ? true : false }
    return (
        <div className={headlineClasses}>
            <div className="text">
                <h1>
                    <Fade bottom delay={50} duration={300}>
                        <div>Creative force</div>
                    </Fade>
                </h1>
                <h1>
                    <Fade  bottom delay={150} duration={300}>
                        <div>for the digital age</div>
                    </Fade>
                </h1>
                
                <Fade  bottom delay={250} duration={300}>
                    <div><p>I'm a designer and full-stack software</p></div>
                </Fade>
                
                
                    <Fade bottom delay={350} duration={300}>
                        <div><p>engineer from Sydney, Australia</p></div>
                    </Fade>
                
            </div>
            <div className="image">
                <img src={loader} alt="Loading..." className={!props.imgLoadComplete ? "loader" : "loader hidden"} />
                <img
                    alt="The creative force is strong with this one"
                    src={props.imgSource}
                    className={!props.imgLoadComplete ? "image hidden" : "image"}
                    onLoad={props.imgLoaded}/>
            </div>
            
        </div>
    )
}

export default Intro
