import React from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';

const Projects = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }
    let projectsText = "Lately, I've been working on..."

    return (
        <div>   
            <div className="regular_text">
                <Fade bottom delay={0} duration={300}>
                    <div>Right now, I'm working on</div>
                </Fade>
                <Fade bottom delay={100} duration={300}>
                    <div>a fintech startup called <a 
                    href="https://paytron.com.au"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline_link"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >Paytron</a>.</div>
                </Fade>
                <Fade bottom delay={200} duration={300}>
                    <div>Check out my older work <a
                    href="/projects"
                    className="inline_link"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >here</a>.</div>
                </Fade>
            </div>
        </div>
    )
}

export default Projects
