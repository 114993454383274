import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var config = {
        apiKey: "AIzaSyBO4o2bTKOLzmCR2CAQPu-fD8rI10tO85Y",
        authDomain: "justinstalscom.firebaseapp.com",
        databaseURL: "https://justinstalscom.firebaseio.com",
        projectId: "justinstalscom",
        storagBucket: "justinstalscom.appspot.com",
        messagingSenderId: "728469458610"
};

firebase.initializeApp(config)
firebase.firestore()

export default firebase;