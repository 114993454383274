import React, { Component } from 'react';
import BottomInfo from '../BottomInfo/BottomInfo';
import SideInfo from '../SideInfo/SideInfo';
import Hello from './ContactSections/Hello';
import Form from './ContactSections/Form';
import Socials from './ContactSections/Socials';
import { connect } from 'react-redux';
import { contact } from '../../store/actions/contactActions';
import * as EmailValidator from 'email-validator';
import './Contact.css';
import FirstImage from './Hi.png';
import ReactTextTransition, { presets } from "react-text-transition";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSection: 1,
            numSections: 3,
            showAnimation: false,
            scrollUp: false,
            scrollDown: false,
            imageClasses: "image",
            sections: ["Email", "Form", "Socials"],
            activeTopic: null,
            topicToggle: false,
            lastY: 0,
            email: "",
            name: "",
            message: "",
            text: {
                one: "",
                two: ""
            },
            transform: 0,
            successfulSubscription: false,
            emailInvalid: false
        }
        this._handleScroll = this._handleScroll.bind(this);
        this._handleMobileScroll = this._handleMobileScroll.bind(this);
        this._setActiveSection = this._setActiveSection.bind(this);
    }
    
    scrollDown() {
        this.setState({
            scrollDown: true
        });
        if (this.state.activeSection < this.state.numSections) {
            this.setState({
                activeSection: this.state.activeSection + 1
            })
        }
        setTimeout(() => {
            this.setState({
                scrollDown: false
            })
        }, 750);
    }
    scrollUp() {
        this.setState({
            scrollUp: true
        });
        if (this.state.activeSection > 1) {
            this.setState({
                activeSection: this.state.activeSection - 1
            })
        }
        setTimeout(() => {
            this.setState({
                scrollUp: false
            })
        }, 750);
    }
    _handleScroll(e) {
        if (e.deltaY > 35) {
            if (!this.state.scrollDown) {
                this.scrollDown()
            }
        }
        if (e.deltaY < -35) {
            if (!this.state.scrollUp) {
                this.scrollUp()
            }
        }
    }
    _handleMobileScroll(e) {
        let currentY = e.touches[0].clientY;
        let lastY = this.state.lastY;
        if (currentY > lastY + 30) {
            if (!this.state.scrollDown) {
                this.scrollDown()
            }
        }
        if (currentY < lastY - 30) {
            if (!this.state.scrollUp) {
                this.scrollUp()
            }
        }
        this.setState({
            lastY: currentY
        })
    }

    componentDidMount() {
        window.addEventListener('wheel', this._handleScroll)
        window.addEventListener('touchmove', this._handleMobileScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this._handleScroll)
        window.removeEventListener('touchmove', this._handleMobileScroll)
      }
    _setActiveSection(section) {  
        this.setState({
            activeSection: section
        })
    }
    _setActiveTopic(index) {
        
        let topic = this.state.messageTopics[index]
        this.setState({
            activeTopic: topic,
            topicToggle: false
        })
        
    }
    toggleTopicMenu = () => {
        if (this.state.topicToggle) {
            this.setState({
                topicToggle: false
            })
        } else {
            this.setState({
                topicToggle: true
            })
        }
    }

    updateEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    updateName = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    updateMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    submitForm = () => {
        if(EmailValidator.validate(this.state.email)) {
            let newContact = {
                email: this.state.email,
                message: this.state.message,
                name: this.state.name
            }
            this.props.contact(newContact);
            console.log(this.state.email, 'subscribed.')
            return this.setState({
                successfulSubscription: true
            });
        } else {
            console.log(this.state.email, 'is not a valid email.');
            this.setState({
                emailInvalid: true
            })
        }
    }

    render() {
        const { email, name, message, activeSection, numPages, text } = this.state;
        let pagesLeft = numPages - activeSection;

        let headlineClasses = "headline"
        if (this.props.menuOpen) {
            headlineClasses = "headline hidden"
        }
        
        return (
            <div className="contact" onScroll={this._handleScroll.bind(this)}>
                
                <section className="component-wrap"  >
                    <div className="width-constraint"  >
                        { activeSection === 1 
                            ?
                                <Hello
                                    menuOpen={this.props.menuOpen}
                                    mouseHover={this.props.mouseHover.bind(this)}
                                    mouseLeave={this.props.mouseLeave.bind(this)}
                                    goToNext={this.scrollDown.bind(this)}
                                /> 
                            : null
                        }
                        { activeSection === 2
                            ?
                                <Form 
                                    updateEmail={this.updateEmail}
                                    updateName={this.updateName}
                                    updateMessage={this.updateMessage}
                                    topicToggle={this.state.topicToggle}
                                    toggleTopicMenu={this.toggleTopicMenu} 
                                    activeTopic={this.state.activeTopic}
                                    messageTopics={this.state.messageTopics}
                                    setActiveTopic={this._setActiveTopic}
                                    email={this.state.email}
                                    name={this.state.name}
                                    submitForm={this.submitForm}
                                    menuOpen={this.props.menuOpen}
                                    emailInvalid={this.state.emailInvalid}
                                    name={name}
                                    email={email}
                                    message={message}
                                />
                            : null
                        }
                        
                        { activeSection === 3 
                            ? 
                                <Socials
                                    menuOpen={this.props.menuOpen}
                                    mouseHover={this.props.mouseHover.bind(this)}
                                    mouseLeave={this.props.mouseLeave.bind(this)}
                                />
                            : null
                        }

                        <BottomInfo
                            sections={this.state.sections}
                            setActiveSection={this._setActiveSection}
                            activeSection={this.state.activeSection}
                            numSections={this.state.numSections}
                            menuOpen={this.props.menuOpen}
                            scrollDirection={pagesLeft}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                            />
                    </div>
                </section>
                
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        contact: (newContact => dispatch(contact(newContact)))
    }
}

export default connect(null, mapDispatchToProps)(Contact)