import React from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';

const Hello = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }
    
    return (
        <div className={headlineClasses}>
            <div className="regular_text">
                <Fade bottom delay={0} duration={300}>
                    <div>For a quick reply
                    </div>
                </Fade>
                <Fade bottom delay={100} duration={300}>
                    <div>say hi with an email
                    
                    {/* or fill in this <button
                    onClick={props.goToNext}
                    className="inline_button"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >quick form</button> */}
                    </div>
                </Fade>
                <Fade bottom delay={200} duration={300}>
                    <div>
                    <a
                    href="mailto:hi@justinstals.com"
                    className="inline_link"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >hi@justinstals.com</a>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Hello
