import React, { Component } from 'react';
import BottomInfo from '../BottomInfo/BottomInfo';
import SideInfo from '../SideInfo/SideInfo';
import Intro from './HomeSections/Intro';
import About from './HomeSections/About';
import _2020 from './HomeSections/2020';
import Projects from './HomeSections/Projects';


// import Typist from 'react-typist'
// import TypistLoop from 'react-typist-loop'
import './Home.css';
import ReactTextTransition, { presets } from "react-text-transition";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false,
            activeSection: 1,
            numSections: 4,
            showAnimation: false,
            scrollUp: false,
            scrollDown: false,
            imageClasses: "image",
            lastY: 0,
            sections: ["Intro", "About", "Work", "Connect"],
            text: {
                one: "",
                two: ""
            },
            transform: 0,
        }
        // this._handleScroll = this._handleScroll.bind(this);
        // this._handleMobileScroll = this._handleMobileScroll.bind(this);
        // this._setActiveSection = this._setActiveSection.bind(this);
    }

    
    scrollDown = () => {
        this.setState({
            scrollDown: true
        });
        if (this.state.activeSection < this.state.numSections) {
            this.setState({
                activeSection: this.state.activeSection + 1
            })
        }
        setTimeout(() => {
            this.setState({
                scrollDown: false
            })
        }, 750);
    }
    scrollUp = () => {
        this.setState({
            scrollUp: true
        });
        if (this.state.activeSection > 1) {
            this.setState({
                activeSection: this.state.activeSection - 1
            })
        }
        setTimeout(() => {
            this.setState({
                scrollUp: false
            })
        }, 750);
    }
    _handleScroll = e => {
        if (e.deltaY > 35) {
            if (!this.state.scrollDown) {
                this.scrollDown()
            }
        }
        if (e.deltaY < -35) {
            if (!this.state.scrollUp) {
                this.scrollUp()
            }
        }
    }
    _handleMobileScroll = e => {
        let currentY = e.touches[0].clientY;
        let lastY = this.state.lastY;
        if (currentY > lastY + 30) {
            if (!this.state.scrollDown) {
                this.scrollDown()
            }
        }
        if (currentY < lastY - 30) {
            if (!this.state.scrollUp) {
                this.scrollUp()
            }
        }
        this.setState({
            lastY: currentY
        })
    }

    componentWillMount() {
        window.scrollTo({ top: 0 })
    }

    componentDidMount() {
        
        window.addEventListener('wheel', this._handleScroll)
        window.addEventListener('touchmove', this._handleMobileScroll)   
    }
    componentWillUnmount() {
        window.removeEventListener('wheel', this._handleScroll)
        window.removeEventListener('touchmove', this._handleMobileScroll)
      }
    _setActiveSection = section => {  
        this.setState({
            activeSection: section
        })
    }
    imageLoaded = () => {
        this.setState({ imageLoaded: true })
    }
      
    render() {
        let activeSection = this.state.activeSection;
        let numPages = this.state.numPages;
        let pagesLeft = numPages - activeSection;
        let text = this.state.text;

        let headlineClasses = "headline"
        if (this.props.menuOpen) {
            headlineClasses = "headline hidden"
        }
        
        return (
            
            <div className="home" onScroll={e => this._handleScroll(e)}>
                {/* <div className={!this.state.imageLoaded ? "wipe" : "wipe hidden"} />
                <div className={!this.state.imageLoaded ? "wipe2" : "wipe2 hidden"} /> */}
                <section className="component-wrap">
                    <div className="width-constraint">
                        
                        { activeSection === 1
                        ?
                        <Intro
                            imgSource="https://firebasestorage.googleapis.com/v0/b/justinstalscom.appspot.com/o/Meditate.gif?alt=media"
                            menuOpen={this.props.menuOpen}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                            imgLoaded={this.imageLoaded}
                            imgLoadComplete={this.state.imageLoaded}
                         />
                        : null }
                        { activeSection === 2
                        ?
                        <About
                            menuOpen={this.props.menuOpen}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                         />
                        : null }
                        { activeSection === 3
                        ?
                        <Projects
                            menuOpen={this.props.menuOpen}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                         />
                        : null }
                        { activeSection === 4
                        ?
                        <_2020
                            menuOpen={this.props.menuOpen}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                         />
                        : null }
                        
                        
                        {/* <img alt="" src={FirstImage} className="image"/> */}
                        {/* <SideInfo
                            menuOpen={this.props.menuOpen}
                            scrollDirection={pagesLeft}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                        /> */}
                        {this.state.imageLoaded ? <BottomInfo
                            sections={this.state.sections}
                            setActiveSection={this._setActiveSection}
                            activeSection={this.state.activeSection}
                            numSections={this.state.numSections}
                            menuOpen={this.props.menuOpen}
                            scrollDirection={pagesLeft}
                            mouseHover={this.props.mouseHover}
                            mouseLeave={this.props.mouseLeave}
                            /> : null }
                        
                    </div>
                </section>
            </div>
        )
    }
}

export default Home