import React from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';

const _2020 = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }
    
    return (
        <div>                   
            <div className="regular_text">
                <Fade bottom delay={0} duration={300}>
                    <div>Want to say hi?</div>
                </Fade>
                <Fade bottom delay={100} duration={300}>
                    <div>You can contact me <a
                    href="/contact"
                    className="inline_link"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >here,</a></div>
                </Fade>
                <Fade bottom delay={200} duration={300}>
                    <div>or send me an <a
                    href="mailto:hi@justinstals.com"
                    className="inline_link"
                    onMouseOver={props.mouseHover}
                    onMouseLeave={props.mouseLeave}
                    >email</a>.</div>
                </Fade>
            </div>
        </div>
    )
}

export default _2020
