import React from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';

const Socials = props =>  {
    
    let headlineClasses = "headline"
    if (props.menuOpen) {
        headlineClasses = "headline hidden"
    }
    let aboutText = "I'm open to full-time product roles in Sydney next year."
    // let aboutText2 = <div>If you'd like to discuss one, please <a href="/contact" className="inline_link">contact me.</a></div>
    // let aboutText3 = 
    
    return (
        <div className={headlineClasses}>
            <Fade bottom delay={0} duration={300}>
                <h1 className="big_link"><a
                href="https://www.linkedin.com/in/JustinStals"
                onMouseOver={props.mouseHover}
                onMouseLeave={props.mouseLeave}
                >LinkedIn</a></h1>
            </Fade>
            <Fade bottom delay={50} duration={300}>
                <h1 className="big_link"><a
                href="https://www.github.com/JustinStals"
                onMouseOver={props.mouseHover}
                onMouseLeave={props.mouseLeave}
                >Github</a></h1>
            </Fade>
            <Fade bottom delay={100} duration={300}>
                <h1 className="big_link"><a
                href="https://www.dribbble.com/JustinStals"
                onMouseOver={props.mouseHover}
                onMouseLeave={props.mouseLeave}
                >Dribbble</a></h1>
            </Fade>
            <Fade bottom delay={150} duration={300}>
                <h1 className="big_link"><a
                href="https://www.sifiblog.com"
                onMouseOver={props.mouseHover}
                onMouseLeave={props.mouseLeave}
                >Blog</a></h1>
            </Fade>
            
        </div>
    )
}

export default Socials
