import React, { Component } from 'react'

export default class ProjectImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          error: false
        };
      }
    
      componentDidMount() {
        const img = new Image();
        img.onload = () => {
          this.setState({
            loaded: true
          });
        };
        img.onerror = () => {
          this.setState({
            error: true
          });
        };
        img.src = this.props.src;
      }
      
    render() {
        if (this.state.error) {
            return <div className={this.props.class + "_loading"}><div/><div/></div>
        } else if (!this.state.loaded) {
            return <div className={this.props.class + "_loading"}><div/><div/></div>
        }
            return <img
            className={this.props.class}
            src={this.props.src}
            alt={this.props.alt} />
    }
}

