import React, {Component} from 'react'
import ReactTextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';
import './BottomInfo.css'

class BottomInfo extends Component {

    render() {
        
        let scrollBarPosition = (this.props.activeSection - 1) * (100 / this.props.numSections) + "%"
        let scrollBarWidth = 100 / this.props.numSections + "%"
        
        let bottomInfoClasses = "bottom_info"

        if (this.props.menuOpen) {
            bottomInfoClasses = "bottom_info hidden"
        }

        return (
            <div className={bottomInfoClasses}>
                <table>
                    <tbody>
                        <tr>
                            {
                                this.props.sections &&
                                this.props.sections.map((section, index) => {
                                    return (
                                        <td key={index}>
                                            <button onClick={() => this.props.setActiveSection(index+1)}
                                                onMouseOver={this.props.mouseHover}
                                                onMouseLeave={this.props.mouseLeave}
                                                style={{ color: (this.props.activeSection - 1 === index) ? "#1E212F" : "#888A91"}}
                                            >
                                                <ReactTextTransition
                                                    text={section}
                                                    delay={100+(index*50)}
                                                    noOverflow
                                                    inline
                                                    springConfig={presets.stiff}
                                                />
                                            </button>
                                        </td>
                                    )
                                })}
                        </tr>
                        <tr>
                            { this.props.numSections && 
                                <td colSpan={this.props.numSections}>
                                    <div className="scroll_section">
                                        <div className="scroll_bar" style={{width: scrollBarWidth, left: scrollBarPosition}}></div>
                                    </div>
                                </td>
                            }
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default BottomInfo
